<template>
    <styled-card
        class="my-5"
        :width="700"
        centered
        hide-toggle>
        <template #heading>
            Add New User
        </template>

        <add-user class="pa-5" />
    </styled-card>
</template>

<script>
import StyledCard from '../globals/StyledCard';
import AddUser from '../globals/AddUser';

export default {
    title: 'New User',
    name: 'UserNew',
    components: {
        StyledCard,
        AddUser
    }
};
</script>
